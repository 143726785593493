var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inner-container"
  }, [_vm.apiRes.value ? _c('ChartLine', {
    attrs: {
      "chartref": _vm.chartref,
      "height": 400,
      "datasets": _vm.datasets,
      "labels": _vm.labels,
      "options": _vm.options,
      "colors": _vm.colors
    },
    on: {
      "click": _vm.click,
      "clickchart": _vm.clickchart
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }