<template lang="html">
  <div
    v-if="hasValue"
    class="inner-container"
  >
    <ChartPie
      :chartref="chartref"
      doughnut
      :colors="colors"
      :options="computeOptions"
      :datasets="datasets"
      @clickchart="clickchart"
    />
    <!-- <div class="doughnut-legend">
      <ul class="legend-list">
        <li
          v-for="value in values"
          :key="value.field"
        >
          <a
            v-if="value.link"
            :style="{ color: value.color }"
            @click="clickLegend(value.link)"
          >
            {{ percentage(value.value, hasValue) }}% &#9679; {{ value.field }}
          </a>
          <span
            v-else
            :style="{ color: value.color }"
          >
            {{ percentage(value.value, hasValue) }}% &#9679; {{ value.field }}
          </span>
        </li>
      </ul>
    </div> -->
  </div>
  <NoData v-else />
</template>

<script>
import {mapActions} from 'vuex'
import {indexToArr} from '@/helpers/arrays'
import ChartPie from '@/components/chart/ChartPie'
import NoData from '@/components/ui/NoData'

export default {
  name: 'WidgetDoughnut',
  components: {
    NoData,
    ChartPie
  },
  props: {
    chartref: {
      type: Number,
      note: 'parent uid for ref',
      default: 0
    },
    apiRes: {
      type: Object,
      required: true
    },
    unit: {
      type: String,
      default: '',
      note: 'unit to use'
    }
  },
  computed: {
    computeOptions () {
      return {}
    },
    values () {
      return this.apiRes.value ? indexToArr(this.apiRes.value) : []
    },
    hasValue () {
      return this.values ? this.values.reduce((acc, val) => {
        acc += Number(val.value || 0)
        return acc
      }, 0) : 0
    },
    datasets () {
      return this.values && this.apiRes.title ? [
        {
          label: this.apiRes.title,
          borderWidth: 0,
          data: this.values.map(o => {
            return {
              label: o.field,
              value: Number(o.value)
            }
          })
        }
      ] : []
    },
    colors () {
      return this.values.map(v => v.color)
    }
  },
  methods: {
    ...mapActions({
      scopeSetFocus: 'scope/setFocus'
    }),
    clickchart (data) {
      this.$emit('close-modal', true)
      const regex = /: \d+/
      const label = data.label.replace(regex, '')
      const dataItem = this.values.find(v => v.field === label)
      if (dataItem && dataItem.link) this.scopeSetFocus(dataItem.link)
    }
  }
}
</script>

<style lang="scss" scoped>
.inner-container {
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    height: 100%;

}
</style>
