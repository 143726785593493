var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inner-container"
  }, [_c('ChartLine', {
    attrs: {
      "chartref": _vm.chartref,
      "height": 400,
      "datasets": _vm.datasets,
      "labels": _vm.labels,
      "unit": _vm.unit,
      "options": {
        elements: {
          point: {
            radius: 3
          }
        }
      },
      "colors": _vm.colors
    },
    on: {
      "click": _vm.click
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }