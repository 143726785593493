var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-tests": "widget-container"
    }
  }, [_c('Widget', {
    staticClass: "tile tile--scale-height",
    class: _vm.isActionableClass,
    attrs: {
      "title": _vm.title,
      "help": _vm.help,
      "widget-style": _vm.widgetStyle,
      "overview": _vm.overview,
      "vendors": _vm.vendors,
      "cookies": _vm.cookies,
      "api-res": _vm.apiResData,
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "type": _vm.type,
      "url": _vm.endpoint,
      "parameters": _vm.parameters
    }
  }, [_c('template', {
    slot: "before"
  }, [_vm._t("before")], 2), _vm._t("default"), _c('template', {
    slot: "after"
  }, [_vm._t("after"), _vm.error ? _c('Alert', {
    attrs: {
      "type": "error",
      "show-icon": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.error.title)), _c('template', {
    slot: "desc"
  }, [_vm._v(" " + _vm._s(_vm.error.desc) + " ")])], 2) : _vm._e()], 2)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }