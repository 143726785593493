var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.hasValue ? _c('div', [_vm.link || _vm.overview ? _c('a', {
    staticClass: "big-number big-number--action",
    on: {
      "click": _vm.click
    }
  }, [_vm._v(" " + _vm._s(_vm.prettyDisplay) + " ")]) : _c('div', {
    staticClass: "big-number"
  }, [_vm._v(" " + _vm._s(_vm.prettyDisplay) + " ")])]) : _c('NoData')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }