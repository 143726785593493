var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "no-widget-data-wrapper"
  }, [_c('div', [_c('strong', [_c('Icon', {
    attrs: {
      "type": "ios-folder-open-outline",
      "size": "26",
      "color": "#999"
    }
  })], 1)]), _c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t('global.no_data')))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }