<template lang="html">
  <div>
    <canvas :id="chartref" />
    <ApiWidgetFocus
      :show="focus"
      :api="apiObj"
      :namespace="'cookie'"
      :description="{title: apiRes.help}"
      :params="{...params,properties: [property.id],period: period}"
    />
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import ApiWidgetFocus from '@/components/ui/ApiWidgetFocus'
import {mapGetters, mapActions} from 'vuex'
import { eventBus } from '@/main'

export default {
  name: 'WidgetBar',
  components: {ApiWidgetFocus},
  props: {
    chartref: {
      type: Number,
      note: 'parent uid for ref',
      default: 0
    },
    apiRes: {
      type: Object,
      required: true
    },
    unit: {
      type: String,
      default: '',
      note: 'unit to use'
    },
    overview: {
      type: Boolean,
      required: false,
      note: 'is called from overview'
    }
  },
  data () {
    return {
      myBarChart: null,
      api: this.apiRes,
      redirect: false,
      apiObj: {
        cookie: 'getCookies',
        vendor: 'getVendors'
      },
      params: {}
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (!this.namespace) return false
      const muatationName = this.namespace + '/GET_' + this.namespace.toUpperCase() + '_PENDING'
      const idCall = this.hash(this.apiRes + this.$route.path)
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === muatationName && mutation.payload.id === idCall) {
          this.isLoading = mutation.payload.state
        }
      })
    })
  },
  mounted () {
    console.log('mounted')
    eventBus.$on('download-chart-as-jpeg', params => {
      const ref = this.chartref
      if (params.id === ref) {
        const canvas = document.getElementById(ref).toDataURL("image/png").replace("image/png", "image/octet-stream")
        const link = document.createElement('a')
        document.getElementById(ref).appendChild(link)
        link.setAttribute('download', params.name)
        link.setAttribute('href', canvas)
        link.click()
        document.getElementById(ref).removeChild(link)
      }
    })
    this.doesRefresh()
  },
  computed: {
    ...mapGetters({
      period: 'scope/selectedDaterange',
      focus: 'scope/focus',
      property: 'scope/selectedProperty',
      qs: 'scope/qs',
      hash: 'hash'
    })
  },
  watch: {
    data: {
      deep: true,
      handler: function (val, old) {
        this.doesRefresh(val)
      }
    },
    apiRes: {
      deep: true,
      handler: function (val) {
        this.api = val
        this.doesRefresh(val)
      }
    }
  },
  methods: {
    ...mapActions({
      setFocus: 'scope/setFocus'
    }),
    doesRefresh (val) {
      return this.createBar(val)
    },
    showDrawer () {
      if (this.redirect && this.redirect.length) {
        return this.$router.push(this.redirect)
      }
      const stringify = JSON.stringify({...this.params, properties: [this.property.id], period: this.period}) + 'cookie'
      // Generate key from params to identify which drawer should be active (will be used on mutation watcher)
      const id = this.hash(stringify)
      this.setFocus(id)
    },
    download (link) {
      window.open(link, '_blank')
    },
    createBar (val = false) {
      const toto = Chart
      this.myBarChart && this.myBarChart.destroy()
      const isHorizontal = this.api.bar && this.api.bar.horizontal
      const riskColor = ['#81C327', '#6BAAD8', '#FFBD41', '#FF7850', '#D55D78', '#D55D7833']
      const data = Object.values(this.api.value.data).map(data => data['1'])
      const labels = Object.values(this.api.value.data).map(data => data['0'])
      if (labels[0].includes('current')) labels[0] = this.property.displayName
      const barData = {
        title: this.api.title,
        labels: labels,
        legend: !isHorizontal,
        datasets: [{
          label: this.api.value.columns[1].title + ` ${this.$t('global.graph.active_on_period')} ` + this.api.startDate + ' - ' + this.api.endDate,
          backgroundColor: isHorizontal ? riskColor : ['#1CA08D ', '#cd8fd2', '#d4a7da', '#dcc0e1', '#e4d8e9', '#e4d8e966'],
          data: data,
          minBarLength: 1,
          borderWidth: 1,
          barThickness: isHorizontal ? 24 : 32
        }]
      }
      const ctx = document.getElementById(this.chartref)
      this.myBarChart = new Chart(ctx, {
        type: 'bar',
        data: barData,
        options: {
          interaction: {
            intersect: false,
            mode: 'nearest',
          },
          indexAxis: isHorizontal ? 'y' : 'x',
          onClick: (event, item) => {
            if (item.length <= 0) return
            if (this.apiRes.bar.cliquable) {
              this.params.lifetimeRange = item[0].index
              this.showDrawer()
            }
            
          },
        }
      })
      return this.myBarChart
    }
  }
}
</script>
