var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.hasValue && _vm.risk ? _c('ul', {
    staticClass: "rating"
  }, [_c('li', {
    class: {
      'active': _vm.risk === 'A'
    },
    attrs: {
      "description": _vm.$t('global.index.excellent')
    }
  }, [_vm._v(" A ")]), _c('li', {
    class: {
      'active': _vm.risk === 'B'
    },
    attrs: {
      "description": _vm.$t('global.index.good')
    }
  }, [_vm._v(" B ")]), _c('li', {
    class: {
      'active': _vm.risk === 'C'
    },
    attrs: {
      "description": _vm.$t('global.index.average')
    }
  }, [_vm._v(" C ")]), _c('li', {
    class: {
      'active': _vm.risk === 'D'
    },
    attrs: {
      "description": _vm.$t('global.index.ngood')
    }
  }, [_vm._v(" D ")]), _c('li', {
    class: {
      'active': _vm.risk === 'E'
    },
    attrs: {
      "description": _vm.$t('global.index.absolute')
    }
  }, [_vm._v(" E ")])]) : _c('NoData')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }