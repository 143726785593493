<template lang="html">
  <div class="inner-container">
    <ChartLine
      v-if="apiRes.value"      
      :chartref="chartref"
      :height="400"
      :datasets="datasets"
      :labels="labels"
      :options="options"
      :colors="colors"
      @click="click"
      @clickchart="clickchart"
    />
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {colors} from '@/config'
import {indexToArr} from '@/helpers/arrays'
import ChartLine from '@/components/chart/ChartLine'

export default {
  name: 'WidgetStackedChart',
  components: {
    ChartLine
  },
  props: {
    chartref: {
      type: Number,
      note: 'parent uid for ref',
      default: 0
    },
    apiRes: {
      type: Object,
      required: true
    },
    unit: {
      type: String,
      default: '',
      note: 'unit to use'
    }
  },
  computed: {
    values () {
      return this.apiRes.value && this.apiRes.value.data ? indexToArr(this.apiRes.value.data) : []
    },
    datasets () {
      if (this.apiRes.value && this.apiRes.value.columns) {
        const sets = Object.keys(this.apiRes.value.columns).map(k => {
          return {k, label: this.apiRes.value.columns[k]}
        }).filter(s => s.label !== 'Day')
        const data = indexToArr(this.apiRes.value.data)
        return sets.map(s => {
          s.data = data.map(i => i[s.k])
          return s
        })
      } else {
        return []
      }
    },
    labels () {
      return this.values.map(l => l['0'])
    },
    colors () {
      return this.apiRes.value && this.apiRes.value.colors ? Object.keys(this.apiRes.value.colors).map(k => this.apiRes.value.colors[k]) : colors.map(c => c.hex)
    },
    dailyLink () {
      return this.apiRes.value && this.apiRes.value.dailylinks ? this.apiRes.value.dailylinks : null
    },
    options () {
      const stacked = this.apiRes.value && Object.values(this.apiRes.value.columns).length > 4
      return {
        scales: {
          y: {
            stacked: false
          }
        },
        elements: {
          point: {
            radius: 2,
            hoverRadius: 8
          }

        }
      }
    }
  },
  methods: {
    ...mapActions({
      setScopeFocus: 'scope/setFocus'
    }),
    // TODO : WHY NOT WORK ?
    click (data) {
      console.log('clic', data)
    },
    clickchart (data) {
      this.$emit('close-modal', true)
      console.info('clickchart', data)
      console.log(this.apiRes.value)
      console.log(this.dailyLink)
      if (this.dailyLink) {
        const baseUrl = this.dailyLink
        const focusUrl = baseUrl.replace('%%DAY%%', data.label)
        this.setScopeFocus(focusUrl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  height: 100%;
 > .chart {
    width: 100%;
  }}
</style>
