<template lang="html">
  <div
    class="widget-map widget-content"
    :ref="chartref"
  >
    <canvas :id="chartref" />
    <ul
      v-if="legend.length"
      class="legend"
    >
      <li
        v-for="i in orderLegend"
        :key="i.color"
      >
        <i :class="'compliance-'+ i.compliance" />{{ i.label }}
      </li>
    </ul>
    <NoData v-if="!values.length" />
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {indexToArr, dataToCountries} from '@/helpers/arrays'
import * as map from 'chartjs-chart-geo'
import Chart from 'chart.js/auto'
import NoData from '@/components/ui/NoData'
import axios from 'axios'
import zoomPlugin from 'chartjs-plugin-zoom'
import { eventBus } from '@/main'
import * as html2canvas from 'html2canvas'

Chart.register(zoomPlugin)
export default {
  name: 'WidgetMap',
  components: {
    NoData,
  },
  props: {
    chartref: {
      type: Number,
      default: 0,
      note: 'widget parent uid'
    },
    apiRes: {
      type: Object,
      required: true
    },
    unit: {
      type: String,
      default: '',
      note: 'unit to use'
    }
  },
  data () {
    return {
      loading: false,
      mapTile: false,
      datasets: [],
      apiErr: [],
      countries: [],
      tileoptions: {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }
    }
  },
  mounted () {
    eventBus.$on('download-chart-as-jpeg', params => {
      const ref = this.chartref
      if (params.id === ref) {
        const y = window.scrollY
        const x = window.scrollX
        window.scrollTo(0,0)
        const canvasFn = html2canvas
        const graph = this.$refs[ref]
        canvasFn(graph).then(cvs => {
          const id = Math.floor(Math.random() * 50) + 1
          cvs.id = id
          cvs.style.top = 0
          cvs.style.left = 0
          cvs.margin = 'auto'
          document.body.appendChild(cvs)
          const canvas = document.getElementById(id).toDataURL("image/png")
          const link = document.createElement('a')
          document.getElementById(id).appendChild(link)
          link.setAttribute('download', params.name)
          link.setAttribute('href', canvas)
          link.click()
          document.getElementById(id).removeChild(link)
          window.scroll(x, y)
          document.body.removeChild(cvs)
        })

        // const canvas = document.getElementById(ref).toDataURL("image/png").replace("image/png", "image/octet-stream")
        // const link = document.createElement('a')
        // document.getElementById(ref).appendChild(link)
        // link.setAttribute('download', params.name);
        // link.setAttribute('href', canvas);
        // link.click()
        // document.getElementById(ref).removeChild(link)
      }
    })
  },
  computed: {
    ...mapGetters({
      countryCnil: 'map/country',
      riskColor: 'vendor/riskColor',
      qs: 'scope/qs',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter'
    }),
    orderLegend () {
      const order = ['A', 'B', 'C', 'D', 'E']
      const legends = this.legend.slice().reverse()
      const ordered = []
      order.forEach(prio => {
        legends.forEach(legend => {
          if (legend.compliance.includes(prio)) ordered.push(legend)
        })
      })
      return ordered
    },
    values () {
      return this.apiRes && this.apiRes.value.data
        ? dataToCountries(this.apiRes.value.data, this.countries).map(c => {
          const countryCode = c.code
          const goodCountryInfo = this.countries.find(elm => elm.code === countryCode)
          goodCountryInfo.color = this.riskColor[String.fromCharCode(parseInt(Math.ceil(goodCountryInfo.vigilance_id)) + 64)]
          return Object.assign({}, c, goodCountryInfo)
        })
        : []
    },
    legend () {
      const legenditems = this.values.reduce((legend, country) => {
        if (!legend.compliance !== String.fromCharCode(parseInt(Math.ceil(country.vigilance_id)) + 64)) {
          const color = country.color
          const label = country.protection
          const cnil = country.protection_cnil
          const compliance = String.fromCharCode(parseInt(Math.ceil(country.vigilance_id)) + 64)

          legend[country.protection] = {
            color,
            label,
            cnil,
            compliance
          }
        }
        return legend
      }, {})
      return Object.keys(legenditems).map(k => legenditems[k])
    }
  },
  methods: {
    ...mapActions({
      scopeSetFocus: 'scope/setFocus'
    }),
    getMapTile () {
      // return axios.get('https://unpkg.com/world-atlas/countries-50m.json').then((r) => {
      return axios.get('/assets/world-atlas/countries-50m.json').then((r) => {
        this.mapTile = r.data
      })
    },
    initMAP () {
      const that = this
      return this.getMapTile()
        .then(() => {
          this.mapCanvas && this.mapCanvas.destroy()
          const data = this.mapTile
          let countries = map.topojson.feature(data, data.objects.countries).features
          let vendorPerCountry = Object.values(this.apiRes.value.data)
          vendorPerCountry.forEach(elm => {
            elm.obj = this.countries.find(country => country.code === elm[0]) || false
          })
          countries.map(elm => {
            elm.obj = vendorPerCountry.find(country => country.obj.num === elm.id) || false
          })
          const aggreateDatasets = []
          countries.forEach((country, i) => {
            if (country.obj) {
              const dataset = {}
              const valueExist = this.values.find(elm => elm.name === country.obj.obj.name)
              dataset.backgroundColor = country.obj ? (valueExist ? valueExist.color : '#ff0') : String.fromCharCode(parseInt(Math.ceil(country.obj.vigilance_id)) + 64)
              // dataset.backgroundColor = country.obj ? (country.obj.obj.vigilance_id <= 1
              //   ? "#81C327"
              //   : country.obj.obj.vigilance_id <=3 ? '#FFBD41' : '#D55D78') : '#fff'
              dataset.hoverOffset = 50
              dataset.data = [{
                description: 'Vendors :',
                longitude: country.obj ? country.obj.obj.longitude : null,
                link: country.obj ? country.obj[2] : false,
                value: country.obj ? country.obj[1] : 0,
                latitude: country.obj ? country.obj.obj.latitude : null,
                name: country.obj ? country.obj.obj.name : ''
              }]
              aggreateDatasets.push(dataset)
            }
          })
          this.datasets = aggreateDatasets
          aggreateDatasets.push({
            outline: countries,
            showOutline: true
          })
          this.mapCanvas = new Chart(document.getElementById(this.chartref).getContext("2d"), {
            type: 'bubbleMap',
            data: {
              labels: countries.map((d) => d.properties.name),
              datasets: aggreateDatasets
              // labels: countries.map((d) => d.properties.name),
            },
            options: {
              onClick: (event, item) => {
                const dataset = that.datasets[item[0].datasetIndex]
                const label = that.mapCanvas.tooltip.title[0] || that.mapCanvas.tooltip.body[0].lines[0]
                const data = dataset.data[item[0].index]
                that.click({ label, dataset, data })
              },
              showOutline: false,
              showGraticule: false,
              plugins: {
                interaction: {

                },
                legend: {
                  onHover: (event) => {
                    event.target.style.cursor = 'pointer'
                  },
                  labels: {
                    boxWidth: 0
                  },
                  display: false
                },
                datalabels: {
                  align: 'top',
                  formatter: (v) => {
                    return 'v.description'
                  }
                },
                tooltip: {
                  mode: 'dataset',
                  callbacks: {
                    title: function(context) {
                      return context[0].raw.name
                    },
                    label: function (context) {
                      return 'vendors: ' + context.raw.value
                    }
                  }
                },
              },
              scales: {
                xy: {
                  projection: 'equirectangular'
                },
                r: {
                  max: 24,
                  min: 0,
                },
              }

            }
          })
          this.loading = true
          this.loading = false
        })
    },
    getCountries () {
      this.loading = true
      this.$axios.get('countries').then(res => {
        this.loading = false
        const type = typeof res.data
        if (type === 'string') {
          this.apiErr.push({
            type: 'warning',
            message: res.data
          })
        }
        if (res.data.success) this.countries = indexToArr(res.data.widget)
        return this.initMAP()
      }).catch(err => {
        this.loading = false
        this.apiErr.push(err)
      })
    },
    fitBounds () {
      const bounds = this.$refs.group.layer.getBounds()
      if (this.values.length) this.$refs.map.layer.fitBounds(bounds)
    },
    click (c) {
      this.$emit('close-modal', true)
      if (c.data.link) this.scopeSetFocus(c.data.link)
    }
  },
  watch: {
    apiRes: {
      immediate: true,
      deep: true,
      handler: function(val, old) {
        if (Object.values(val).length) this.getCountries()
      }
    },
    qs: {
      imediate: false,
      handler: function (val, old) {
        if (this.countries.length > 0) return this.initMAP()
      }
    }
  }
}
</script>

<style lang="scss">
.widget-map {
  .legend{
  position: relative;
  bottom: 40px;
  background : #ffffff;
  padding:10px 16px 0 16px;
  columns: 1;
  }
  .no-data {
    padding: 16px;
  }
  //
  //.map-legend {
  //  display: flex;
  //  align-items: center;
  //  justify-content: space-around;
  //
  //  padding: 10px 0;
  //
  //  .map-legend-label,
  //  .map-legend-border {
  //    display: inline-block;
  //    vertical-align: middle;
  //  }
  //
  //  .map-legend-border {
  //    margin-right: 10px;
  //    border: 1px solid;
  //    width: 16px;
  //    height: 16px;
  //    border-radius: 8px;
  //  }
  //
  //  .map-legend-color {
  //    width: 18px;
  //    height: 18px;
  //    opacity: .3;
  //    display: block;
  //    width: 14px;
  //    height: 14px;
  //    border-radius: 7px;
  //  }
  //}
}

.map-tooltip {
  width: 200px;
  white-space: normal;

  .cnil-description {
    font-size: 10px;
    margin-top: 3px;
    max-width: 200px;
  }
}

</style>
