<template>
  <chart
    :chartref="chartref"
    :type="type"
    :image="image"
    :datasets="doughnutData"
    :options="options"
    :width="width"
    :height="height"
    @clickchart="click"
    @ready="ready"
  />
</template>

<script>
/**
* Chart component for pie and doughnut charts.
*/

import { colors } from '@/config'
import Chart from './Chart'
export default {
  name: 'ChartPie',
  components: {
    Chart
  },
  props: {
    chartref: {
      type: Number,
      note: 'parent uid for ref',
      default: 0
    },
    /**
     * Datasets
     */
    datasets: {
      type: Array,
      required: true
    },
    /**
     * Set to display a Doughnut
     */
    doughnut: {
      type: Boolean,
      default: false
    },
    /**
     * Overwrite and merged with default options
     */
    options: {
      type: Object,
      default: () => {}
    },
    /**
     * Canvas width (responsive so used as a ratio)
     */
    width: {
      type: Number,
      default: 400
    },
    /**
     * Canvas height (responsive so used as a ratio)
     */
    height: {
      type: Number,
      default: 400
    },
    /**
     * If true, chart is displayed as a png, not a canvas (useful for pdf)
     */
    image: {
      type: Boolean,
      default: false
    },
    /**
     * Array of colors applied to datasets
     */
    colors: {
      type: Array,
      default: () => colors.map(c => c.hex)
    }
  },
  computed: {
    type () {
      return this.doughnut ? 'doughnut' : 'pie'
    },
    doughnutData () {
      let labels = []
      for (let dataset of this.datasets) {
        for (let data of dataset.data) {
          if (!labels.includes(data.label)) {
            labels.push(data.label)
          }
        }
      }
      let datasets = []
      for (let dataset of this.datasets) {
        let data = []
        let backgroundColor = []
        for (let i = 0; i < labels.length; i++) {
          const label = labels[i]
          const setDataLabel = dataset.data.find(d => d.label === label)
          if (setDataLabel) {
            data.push(setDataLabel.value)
          } else {
            data.push(0)
          }
          backgroundColor.push(this.colors[i])
        }
        let borderWidth = 0
        if (this.datasets.length > 0) {
          borderWidth = 1
        }
        datasets.push({
          label: dataset.label,
          data,
          backgroundColor,
          borderWidth
        })
      }
      return {
        labels,
        datasets
      }
    }
  },
  methods: {
    click (data) {
      /**
       * Returns { type: 'data', label, value, dataset } or { type: 'dataset', dataset }
       *
       * @event click
       * @type {object}
       */
      this.$emit('clickchart', data)
    },
    ready () {
      /**
       * Emited when animations are complete, useful to know when png version is available in the DOM
       *
       * @event ready
       * @type {event}
       */
      this.$emit('ready')
    }
  }
}
</script>

<style lang="scss">
// .doughnut {
//   padding: 20px;
// }
</style>
