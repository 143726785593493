<template lang="html">
  <div
    :id="'chart-graph-' + chartref"
    :ref="'chart-graph-' + chartref"
  >
    <Select
      id="search-vendor-graph-bar"
      class="graph-search-bar"
      filterable
      @on-change="handleGraphFilterChange"
      :placeholder="$t('global.graph.find_vendor')"
    >
      <Option
        v-for="item in getVendorNodesList"
        :value="item.value"
        :key="item.value"
      >
        {{ item.label }}
      </Option>
    </Select>
    <br><br>
    <div class="overlay">
      <Card
        v-if="selectedNode"
        class="graph-vendor-card"
      >
        <template slot="title">
          {{ selectedNode.vendorName || $t('global.filters.under_qualification') }}
        </template>
        <ul>
          <li v-if="selectedNode.group == 0">
            <i class="compliance-0" />{{ $t('global.graph.website') }}
          </li>
          <li v-if="selectedNode.group == 1">
            <i class="compliance-A" />{{ $t('global.graph.direct_call_only') }}
          </li>
          <li v-if="selectedNode.group == 2">
            <i class="compliance-B" />{{ $t('global.graph.direct_n_tms') }}
          </li>
          <li v-if="selectedNode.group == 3">
            <i class="compliance-C" />{{ $t('global.graph.direct_n_indirect') }}
          </li>
          <li v-if="selectedNode.group == 4">
            <i class="compliance-D" />{{ $t('global.graph.indirect_call_only') }}
          </li><li v-if="selectedNode.group == 5">
            <i class="compliance-E" />{{ $t('global.graph.indirect_call_only_to_vendor') }}
          </li>
        </ul>
        <br>
        <span style="display:block; cursor:pointer; color:#1CA08D; font-weight:bold"
              @click="showDrawer"
        > {{ $t('global.trackers') }} : <span
          v-if="selectedNode.uniqueTrackersCount === 0"
          class="chips chips--green"
        > {{ $t('global.no') }} </span>  <span
          v-else
          class="chips chips--orange"
        > {{ selectedNode.uniqueTrackersCount }} </span>
        </span>
        <ApiWidgetFocus :show="focus"
                        :api="{cookie: 'getCookies', pgtrackers: 'getPgTrackers'}"
                        :namespace="'cookie'"
                        :description="{title: 'Trackers drop by ' + selectedNode.vendorName}"
                        :params="{...trackersParams,properties: [property.id],period: period}"
        />
        <br>
        <br>
        {{ this.$t('global.graph.requests') }} <span class="chips chips--orange">{{ selectedNode.numRequests || 'N/A' }}</span>
        <ul>
          <li>- {{ this.$t('global.graph.from_website') }} <span class="chips chips--orange">{{ Math.round(selectedNode.ratioDirectStrict * 100, 1) + '%' || 'N/A' }}</span></li>
          <li>- {{ this.$t('global.graph.from_vendors') }} <span class="chips chips--orange">{{ Math.round((1 - selectedNode.ratioDirectStrict) * 100, 1) + '%' || 'N/A' }}</span></li>
        </ul>
        <br>
        <ul>
          <li>{{ this.$t('global.domains') }} - {{ Object.values(selectedNode.slds).length }}</li>
          <li
            v-for="(value, name) in selectedNode.slds"
            :key="name"
          >
            &nbsp; - {{ name }} : {{ value }}
          </li>
        </ul>
        <br>
        <ul>
          <li>{{ this.$t('Requested by') }} - {{ Object.values(selectedNode.initiatorSlds).length }}</li>
          <li
            v-for="(value, name) in selectedNode.initiatorSlds"
            :key="name"
          >
            &nbsp; - {{ name }} : {{ value }}
          </li>
        </ul>
        <br>
        <router-link
          v-if="selectedNode.vendorUuid"
          tag="div"
          :to="{name: 'Vendors view', params: {uuid: selectedNode.vendorUuid}}"
        >
          <Button
            type="primary"
            icon="ios-link"
            class="btn--elevated"
            long
          >
            {{ $t('global.graph.view_vendor') }}
          </Button>
        </router-link>
      </Card>
    </div>
    <div v-if="isLoading">
      <Progress
        :percent="progressText"
        :stroke-color="['#81C327', '#6BAAD8']"
      />
    </div>
    <div
      v-if="showGraph"
      :id="graphId"
    />
    <div id="legend">
      <ul>
        <li><i class="compliance-0" />{{ $t('global.graph.website') }}</li>
        <li><i class="compliance-A" />{{ $t('global.graph.direct_call_only') }}</li>
        <li><i class="compliance-B" />{{ $t('global.graph.direct_n_tms') }}</li>
        <li><i class="compliance-C" />{{ $t('global.graph.direct_n_indirect') }}</li>
        <li><i class="compliance-D" />{{ $t('global.graph.indirect_call_only') }}</li>
        <li><i class="compliance-E" />{{ $t('global.graph.indirect_call_only_to_vendor') }}</li>
      </ul>
      <ul>
        <li class="shape">
          <svg
            width="25"
            height="21"
          >
            <polygon
              fill="transparent"
              stroke-width="1.5"
              stroke="black"
              points="2 18, 10 2, 18 18"
            />
          </svg>
          Trackers dropped
        </li>
        <li class="shape">
          <svg
            width="25"
            height="21"
          >
            <circle
              fill="transparent"
              stroke-width="1.5"
              stroke="black"
              r="8"
              cx="10"
              cy="10"
            />
          </svg>
          No trackers dropped
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ApiWidgetFocus from '@/components/ui/ApiWidgetFocus'
import SldDetails from '@/views/Cookies/SldDetails'

import { eventBus } from '@/main'
import * as vis from 'vis'
import * as html2canvas from 'html2canvas'

export default {
  name: 'WidgetGraph',
  components: { ApiWidgetFocus },
  props: {
    chartref: {
      type: Number,
      default: 0
    },
    apiRes: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      progressText: 0,
      sldData: {},
      sldOpen: false,
      graphId: '',
      selectedNode: null,
      showGraph: true,
      trackersParams: {},
      graphValues: [],
      data: [],
      network: {},
      graph: {
        lockedColor: '#f0f0f0', // PiggyBacking
        unlockedColor: '#f0f0f0', // Hardcoded
        selectedColor: '#f0f0f0', // Website (Property)
        nodes: new vis.DataSet([]),
        edges: new vis.DataSet([]),
        nodesArray: [],
        edgesArray: []
      },
      percent: 0,
      isLoading: true,
      groups: {
        '0': {
          shape: 'hexagon',
          color: '#707070' // red
        },
        '1': {
          shape: 'triangle',
          color: '#81C327' // orange
        },
        '2': {
          shape: 'triangle',
          color: '#6BAAD8' // green
        },
        '3': {
          shape: 'triangle',
          color: '#FFBD41' // blue
        },
        '4': {
          shape: 'triangle',
          color: '#FF7850' // purple
        },
        '5': {
          shape: 'triangle',
          color: '#D55D78' // green
        },
        '6': {
          shape: 'dot',
          color: '#81C327' // orange
        },
        '7': {
          shape: 'dot',
          color: '#6BAAD8' // green
        },
        '8': {
          shape: 'dot',
          color: '#FFBD41' // blue
        },
        '9': {
          shape: 'dot',
          color: '#FF7850' // purple
        },
        '10': {
          shape: 'dot',
          color: '#D55D78' // green
        }
      }
    }
  },
  watch: {
    apiRes: {
      immediate: true,
      deep: true,
      handler (data) {
        const {value} = data
        const [hardCodedVendors, piggyBackingOnlyVendors] = JSON.parse(value)
        this.graphValues = hardCodedVendors.concat(piggyBackingOnlyVendors)
        this.updateGraph()
        this.$nextTick(() => {
          this.initGraph()
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      property: 'scope/selectedProperty',
      focus: 'scope/focus',
      hash: 'hash',
      period: 'scope/selectedDaterange'
    }),
    getCurrentPropertyName () {
      return ((this.apiRes && this.apiRes.propertyNames[0]) || this.$t('global.graph.website'))
    },
    getVendorNodesList () {
      return Object.values(this.graph.nodes._data).filter(n => n.isVendor).map(n => ({
        label: n.vendorName,
        value: n.id
      })) || []
    }
  },
  methods: {
    ...mapActions({
      setFocus: 'scope/setFocus'
    }),
    showDrawer () {
      const t = this
      const vendorId = this.selectedNode.id
      this.trackersParams = {vendorIds: vendorId}
      const stringify = JSON.stringify({...this.trackersParams, properties: [this.property.id], period: this.period}) + 'cookie'
      // Generate key from params to identify which drawer should be active (will be used on mutation watcher)
      const id = this.hash(stringify)
      this.setFocus(id)
    },
    handleGraphFilterChange (nodeId) {
      if (nodeId) {
        this.selectedNode = this.graph.nodes.get(nodeId)
        this.network.focus(nodeId, {
          scale: 1.5,
          animation: false
        })
      } else {
        this.selectedNode = {}
        this.network.focus(1, {
          scale: 1.5,
          animation: false
        })
        this.$Message.error(this.$t('global.graph.vendor_not_found'))
      }
    },
    initGraph () {
      // canvas
      const container = document.getElementById(this.graphId)
      // const loadingBar = document.querySelector('.graph-loader')

      const data = {
        nodes: this.graph.nodes,
        edges: this.graph.edges
      }

      const afterZoomLimit = {
        scale: 0.40
      }

      const options = {
        // width: '1286px',
        height: '600px',
        interaction: {
          selectConnectedEdges: false,
          hideEdgesOnDrag: false,
          hover: true
        },
        nodes: {
          shape: 'dot',
          size: 2,
          // color: this.graph.lockedColor,
          scaling: {
            label: {
              min: 12,
              max: 20
            }
          },
          font: {
            multi: 'html',
            face: 'Montserrat, Raleway, Helvetica, Arial',
            size: 12,
            bold: {
              mod: '400',
              face: 'Montserrat, Raleway, Helvetica, Arial',
              size: 12,
              color: '#333'
            },
            color: '#333'
          },
          borderWidth: 1,
          shadow: false
          // shadow: true
        },
        edges: {
          smooth: {type: 'continuous'}, // color: this.graph.lockedColor
          color: '#f0f0f0'
        },
        physics: {
          enabled: true,
          adaptiveTimestep: true,
          barnesHut: {
            gravitationalConstant: -8000,
            springConstant: 0.04,
            springLength: 125
          },
          stabilization: {
            // iterations: 987
            iterations: 256
          }
        },
        layout: {
          randomSeed: 191006,
          improvedLayout: true
        },

        groups: this.groups
      }

      if (!container || !data) {
        return
      }

      const network = new vis.Network(container, data, options)
      const t = this
      // this.network = network

      network.on("beforeDrawing",  function(ctx) {
        // save current translate/zoom
        ctx.save()
        // reset transform to identity
        ctx.setTransform(1, 0, 0, 1, 0, 0)
        // fill background with solid white
        ctx.fillStyle = '#ffffff'
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        // restore old transform
        ctx.restore()
      })
      network.on("stabilizationProgress", function (params) {
        const widthFactor = params.iterations / params.total
        t.progressText = (Math.round(widthFactor * 100))
      })
      // network.on('zoom', () => {
      //   console.log(network.getScale())
      //   if (network.getScale() <= afterZoomLimit) {
      //     network.moveTo(afterZoomLimit)
      //   }
      // })
      // network.on('resize', () => {
      //   network.fit({
      //     minZoomLevel: 0.2,
      //     maxZoomLevel: 0.2,
      //     animation:false
      //   })
      //   network.fit()
      //   console.log('resized and fitted')
      //   console.log(network.getScale())
      // })
      const networkCanvas = document
        .getElementById(this.graphId)
        .getElementsByTagName('canvas')[0]

      network.on('hoverNode', () => {
        networkCanvas.style.cursor = 'grab'
      })

      network.on('blurNode', () => {
        networkCanvas.style.cursor = 'default'
      })

      // var radius = 150
      //
      // network.on('initRedraw', function () {
      //   var ids = data.nodes.getIds()
      //   var d = 2 * Math.PI / ids.length // Angular pitch
      //   ids.forEach(function (id, i) {
      //     var x = radius * Math.cos(d * i)
      //     var y = radius * Math.sin(d * i)
      //     network.moveNode(id, x, y)
      //   })
      // })

      network.on('selectNode', (params) => {
        if (params.nodes.length === 1) {
          if (network.isCluster(params.nodes[0]) === true) {
            network.openCluster(params.nodes[0])
          }
        }
      })

      network.on('click', (properties) => {
        const clickedNodesHead = this.graph.nodes.get(properties.nodes)[0]
        // if (clickedNodesHead && clickedNodesHead.isVendor) {
        if (clickedNodesHead && clickedNodesHead.id !== 'WEBSITE') {
          this.selectedNode = clickedNodesHead
        } else {
          this.selectedNode = null
        }
      })

      // Initial Graph load END
      network.once('stabilizationIterationsDone', () => {
        t.progressText = 100
        network.focus('WEBSITE', {scale: 0.75})
        this.isLoading = false
      })

      // Monitor graph stabilization
      /* network.on('startStabilizing', () => {
        container.style.visibility = 'hidden'
        this.isLoading = true
      }) */

      network.on('stabilized', () => {
        this.network = network
        this.isLoading = false
      })

      network.moveTo({scale: 1})
    },
    updateGraph () {
      this.graph.nodesArray = []
      this.graph.edgesArray = []
      this.graph.nodes.clear()
      this.graph.edges.clear()

      this.graph.nodesArray.push({
        id: 'WEBSITE',
        label: `<b>${this.getCurrentPropertyName}</b>`, // color: this.graph.selectedColor,
        group: '0',
        borderWidth: 3,
        value: 3000,
        level: 0
      })

      // this.graph.nodesArray.push({
      //   id: 'UNKNOWN',
      //   label: `<b>Vendors Under Qualification</b>`,
      //   group: '5',
      //   value: 1200,
      //   level: 0
      // })

      this.updateNodes()
      this.updateEdges()
    },
    updateNodes () {
      this.graphValues.forEach((currentNode) => {
        const node = {}
        node.uniqueTrackersCount = currentNode.uniqueTrackersCount
        node.id = !currentNode.vendorId
          ? currentNode.isInitiatorProperty === 1
            ? 'UNKNOWN (Direct)'
            : 'UNKNOWN'
          : currentNode.vendorId
        //
        if (currentNode.vendorName && currentNode.vendorName.toUpperCase() === this.getCurrentPropertyName.toUpperCase()) {
          node.id = 'WEBSITE'
          currentNode.isInitiatorProperty === 1
        }

        if (!node.slds) {
          node.slds = {}
        }
        if (node.slds[currentNode.sld]) {
          node.slds[currentNode.sld] += parseInt(currentNode.numRequests)
        } else {
          node.slds[currentNode.sld] = parseInt(currentNode.numRequests)
        }
        if (!node.initiatorSlds) {
          node.initiatorSlds = {}
        }
        if (node.initiatorSlds[currentNode.initiatorSld]) {
          node.initiatorSlds[currentNode.initiatorSld] += parseInt(currentNode.numRequests)
        } else {
          node.initiatorSlds[currentNode.initiatorSld] = parseInt(currentNode.numRequests)
        }

        // clustering under qualification
        node.label = !currentNode.vendorName
          ? currentNode.isInitiatorProperty === 1
            ? currentNode.sld
            : currentNode.sld
          : `${currentNode.vendorName}`





        // node.label = !currentNode.vendorName
        //   ? currentNode.isInitiatorProperty === 1
        //     ? '(direct) UNDER QUALIFICATION'
        //     : '(jump) UNDER QUALIFICATION'
        //   : `${currentNode.vendorName}`
        // node.level = (currentNode.isInitiatorProperty === true) ? 1 : 2

        // node.label = `<b>${currentNode.vendorName || '(Direct) UNDER QUALIFICATION'}</b>`

        node.group = currentNode.group
        node.group = !currentNode.vendorId
          ? currentNode.isInitiatorProperty === 1
            ? 2
            : 5
          : currentNode.group

        if (node.uniqueTrackersCount === 0) {
          node.group += 5
        }

        // node.font = {
        //   size: 15,
        //   color: 'red',
        //   face: 'courier',
        //   strokeWidth: 3,
        //   strokeColor: '#ffffff'
        // }
        // console.log(currentNode)
        node.vendorUuid = currentNode.vendorUuid
        node.vendorName = currentNode.vendorName
        node.numRequests = parseInt(currentNode.numRequests)
        node.ratioDirectStrict = currentNode.ratio_direct_strict

        node.isVendor = !!currentNode.vendorName
        node.isUnderSurveillance = !!parseInt(currentNode.numSurveillance) > 0
        node.initiatorId = currentNode.isInitiatorProperty === 1
          ? 'WEBSITE' // this.getCurrentPropertyName
          : !currentNode.initiatorVendorId
            ? 'UNKNOWN'
            : currentNode.initiatorVendorId

        // node.chosen.edge.color.opacity = 1

        if (!this.graph.nodesArray.find(n => n.id === node.id)) {
          node.value = parseInt(currentNode.numRequests)
          this.graph.nodesArray.push(node)
        } else {
          this.graph.nodesArray.find((existingNode, idx) => {
            if (existingNode.id === node.id) {
              this.graph.nodesArray[idx].value += node.numRequests
              this.graph.nodesArray[idx].numRequests = existingNode.value

              for (const [sld, numRequests] of Object.entries(node.slds)) {
                // console.log(sld)
                if (this.graph.nodesArray[idx].slds) {
                  if (this.graph.nodesArray[idx].slds[sld]) {
                    this.graph.nodesArray[idx].slds[sld] += numRequests
                  } else {
                    this.graph.nodesArray[idx].slds[sld] = numRequests
                  }
                }
              }
              for (const [sld, numRequests] of Object.entries(node.initiatorSlds)) {
                if (this.graph.nodesArray[idx].initiatorSlds) {
                  if (this.graph.nodesArray[idx].initiatorSlds[sld]) {
                    this.graph.nodesArray[idx].initiatorSlds[sld] += numRequests
                  } else {
                    this.graph.nodesArray[idx].initiatorSlds[sld] = numRequests
                  }
                }
              }
            }
          })
        }
      })

      this.graph.nodes.add(this.graph.nodesArray)
    },
    updateEdges () {
      this.graphValues.map(currentNode => {
        const initiator = currentNode.isInitiatorProperty === 1
          ? 'WEBSITE' // this.getCurrentPropertyName
          : !currentNode.initiatorVendorId
            ? 'UNKNOWN'
            : currentNode.initiatorVendorId

        const triggered = !currentNode.vendorId
          ? currentNode.isInitiatorProperty === 1
            ? 'UNKNOWN (Direct)'
            : 'UNKNOWN'
          : currentNode.vendorId
        const nodeLength = (200 * (2 - parseInt(currentNode.ratio_direct_strict)))

        if (typeof this.graph.edgesArray.find(e => e.from === initiator && e.to === triggered) === 'undefined') {
          if (initiator !== triggered) {
            if (currentNode.isInitiatorProperty === 1) {
              this.graph.edgesArray.push({
                from: initiator,
                to: triggered, // value: value,
                length: nodeLength,
                color: {
                  color: '#d0d0d0',
                  hover: '#707070',
                  inherit: false,
                  highlight: '#f0f0f0',
                  opacity: 0.5
                },
                arrows: {
                  to: {
                    enabled: true,
                    scaleFactor: 0.5,
                    type: 'arrow'
                  }
                }
              })
            } else {
              this.graph.edgesArray.push({
                from: initiator,
                to: triggered, // value: value,
                length: nodeLength,
                color: {
                  color: '#d8d8d8',
                  hover: '#707070',
                  inherit: false,
                  highlight: '#f0f0f0',
                  opacity: 0.5
                },
                arrows: {
                  to: {
                    enabled: true,
                    scaleFactor: 0.5,
                    type: 'arrow'
                  }
                }
              })
            }
          }
        }
      })
      this.graph.edges.add(this.graph.edgesArray)
    }
  },
  mounted () {
    eventBus.$on('download-chart-as-jpeg', params => {
      const ref = this.chartref
      if (ref && ref === params.id) {
        const domChartToDownload = this.$refs['chart-graph-' + ref]
        // domChartToDownload && domChartToDownload.classList.add('fullpage_for_download')
        // const networkcanvas = domChartToDownload.querySelector('.vis-network')
        console.log(this.network)
        this.network.setOptions({
          width: '300%',
          height: '1800px',
        })
        this.network.fit()
        setTimeout(()=>{
          const canvasToDownload = domChartToDownload.querySelector('canvas')
          const link = document.createElement('a')
          canvasToDownload.appendChild(link)
          link.setAttribute('download', params.name)
          link.setAttribute('href', canvasToDownload.toDataURL("image/png"))
          link.click()
          canvasToDownload.removeChild(link)
          this.network.setOptions({
            width: '100%',
            height: '600px',
          })
          this.network.fit()
          //   // domChartToDownload && domChartToDownload.classList.remove('fullpage_for_download')
        }, 500)

      }

      // if (ref === params.id) {
      //   const y = window.scrollY
      //   const x = window.scrollX
      //   window.scrollTo(0,0)
      //   const canvasFn = html2canvas
      //   canvasFn(domChartToDownload).then(cvs => {
      //     const id = Math.floor(Math.random() * 50) + 1
      //     cvs.id = id
      //     cvs.style.top = 0
      //     cvs.style.left = 0
      //     cvs.margin = 'auto'
      //     document.body.appendChild(cvs)
      //     const canvas = document.getElementById(id).toDataURL("image/png")
      //     const link = document.createElement('a')
      //     document.getElementById(id).appendChild(link)
      //     link.setAttribute('download', params.name)
      //     link.setAttribute('href', canvas)
      //     link.click()
      //     document.getElementById(id).removeChild(link)
      //     window.scroll(x, y)
      //     document.body.removeChild(cvs)
      //   })
      //  }
    })
    this.graphId = `graph-network-${Date.now().toString()}`
    this.initGraph()
  }
}
</script>

<style lang="scss" scoped>
.overlay{
  position:absolute;
  // width:100%;
  z-index:99;
}
</style>
<style lang="scss" >
.fullpage_for_download{
  .vis-network{
    canvas {
      background: #fff;
      width: 2560px !important;
      height: 1600px !important;
    }
  }
}


</style>
