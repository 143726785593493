<template lang="html">
  <div class="widget-table widget-content">
    <NoData v-if="!data.length" />

    <Table
      v-else
      class="table with-header-border"
      stripe
      size="small"
      :border="false"
      :disabled-hover="!link"
      :columns="columns"
      :data="data"
      :row-class-name="rowClassName"
      @on-row-click="click"
    />
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import NoData from '@/components/ui/NoData'
import {colsToArr, dataToArr} from '@/helpers/arrays'

export default {
  name: 'WidgetTable',
  components: {
    NoData
  },
  props: {
    apiRes: {
      type: Object,
      required: true
    }
  },
  computed: {
    columns () {
      const cols = this.apiRes.value ? colsToArr(this.apiRes.value.columns).columns : []

      let col = cols.map(c => {
        if (c.title === 'Vendor') {
          c = {
            ...c,
            render: (h, params) => {
              if (params.row[params.column.key] !== this.$t('global.vendor.progress')) {
                return h('Tooltip', {
                  props: {
                    content: this.$t('global.graph.view_vendor'),
                    placement: 'right',
                    width: 200
                  }
                }, [
                  h('span', {
                    attrs: {
                      class: 'chips chips--block-item chips--green chips--action'
                    },
                    on: {
                      click: () => {
                        const TargetLinkIdColIndex = cols.findIndex(el => el.title === 'TargetLinkId')
                        if (TargetLinkIdColIndex !== -1) {
                          this.clearFocus()
                          this.routerPush('Vendors view', {uuid: params.row[TargetLinkIdColIndex]})
                        } else {
                          this.clearFocus()
                          this.routerPush('Vendors view', {name: params.row[params.column.key]})
                        }
                      }
                    }
                  }, params.row[params.column.key])
                ])
              } else {
                return h('div', {
                  class: 'qualification-indicator'
                }, this.$t('global.filters.under_qualification'))
              }
            }
          }
        }
        // handle focus widget
        if (c.type === 'view_all_link') {
          c = {
            ...c,
            render: (h, params) => {
              // TODO: Update endpoint or table or make the query easier and cleaner/
              // Will diseapear when we'll upgrade every old wcookie/wvendors endpoints with the api
              console.log(params.row[3])
              const link = `${c.link}&selected_domain_id=${params.row[3]}&selected_vendor_id=${params.row[3]}` // domain_name / vendor_id
              console.log(link)
              return h('Button', {
                attrs: {
                  type: 'text'
                },
                on: {
                  click: () => {
                    this.setScopeFocus(link)
                  }
                }
              }, [
                h('span', {}, `${this.$t('global.view_all')} (${params.row[params.column.key]})`),
                h('Icon', {props: {type: 'ios-arrow-forward'}})
              ])
            }
          }
        }
        return c
      })

      const TargetLinkIdColIndex = cols.findIndex(el => el.title === 'TargetLinkId')
      if (TargetLinkIdColIndex > -1) {
        col.splice(TargetLinkIdColIndex, 1)
      }

      return col
    },
    data () {
      return this.apiRes && this.apiRes.value.data ? dataToArr(this.apiRes.value.data, this.apiRes.value.class) : []
    },
    link () {
      return this.apiRes && this.apiRes.value.columns ? colsToArr(this.apiRes.value.columns).links : ''
    }
  },
  methods: {
    ...mapActions({
      setScopeFocus: 'scope/setFocus'
    }),
    routerPush (name, params) {
      this.$router.push({name, params})
    },
    click (row) {
      if (this.link) this.setScopeFocus(row[this.link])
    },
    clearFocus () {
      this.focus = ''
    },
    rowClassName (row, index) {
      return row.className
    }
  }
}
</script>

<style lang="scss">
    .widget-table {
        height: 100%;
        padding: 1rem 0.5rem;

        .table {
            .ivu-table {
                height: 100% !important;
            }
        }
    }
</style>
