var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('canvas', {
    attrs: {
      "id": _vm.chartref
    }
  }), _c('ApiWidgetFocus', {
    attrs: {
      "show": _vm.focus,
      "api": _vm.apiObj,
      "namespace": 'cookie',
      "description": {
        title: _vm.apiRes.help
      },
      "params": Object.assign({}, _vm.params, {
        properties: [_vm.property.id],
        period: _vm.period
      })
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }