<template lang="html">
  <div class="no-widget-data-wrapper">
    <div>
      <strong>
        <Icon
          type="ios-folder-open-outline"
          size="26"
          color="#999"
        />
      </strong>
    </div>
    <div>
      <strong>{{ $t('global.no_data') }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoData'
}
</script>

<style lang="scss" scoped>
    .no-widget-data-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        text-align: center;
    }
</style>
