<template lang="html">
  <div>
    <div v-if="hasValue">
      <a
        v-if="link || overview"
        class="big-number big-number--action"
        @click="click"
      >
        {{ prettyDisplay }}
      </a>
      <div
        v-else
        class="big-number"
      >
        {{ prettyDisplay }}
      </div>
      <!-- <span class="detail" v-if="vendors">{{this.getDetails('vendors')}}</span>
      <span class="detail" v-if="cookies">{{this.getDetails('cookies')}}</span> -->
    </div>
    <NoData v-else />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import NoData from '@/components/ui/NoData'

export default {
  name: 'WidgetOneValue',
  components: {
    NoData
  },
  props: {
    colorClass: {
      type: String,
      default: ''
    },
    apiRes: {
      type: Object,
      required: true
    },
    unit: {
      type: String,
      default: '',
      note: 'unit to use'
    },
    vendors: {
      type: Boolean,
      default: false
    },
    cookies: {
      type: Boolean,
      default: false
    },
    overview: {
      type: Boolean,
      default: false
    },
    routeLink: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasValue () {
      return this.apiRes.value.length
    },
    link () {
      return this.apiRes.link
        ? this.apiRes.link
        : ''
    },
    prettyDisplay () {
      return !isNaN(Number(this.apiRes.value))
        ? Number(this.apiRes.value).toLocaleString()
        : this.apiRes.value
    }
  },
  methods: {
    ...mapActions({
      setScopeFocus: 'scope/setFocus'
    }),
    click () {
      if (this.link.length && !this.overview) this.setScopeFocus(this.link)
      if (this.overview && this.vendors) this.$router.push('/vendors')
    }
  }
}
</script>

<style lang="scss" scoped>
//.inner-container {
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  justify-content: center;
//  height: 100%;
//
//  .detail {
//    display: block;
//  }
//
//  .text--fluid-number {
//    word-break: break-word;
//    text-align: center;
//    text-size-adjust: 80%;

.big-number {
  font-weight: 100;
  font-size: 80px;
  word-break: break-word;
  text-align: center;
  //font-size: 100px;
  //font-family: "Montserrat Alternates";
  color: #8A9190;
  display: block;
  line-height: 1;

  &.big-number--action {
    color: #1CA08D;
  }
}

//  }
//}
</style>
