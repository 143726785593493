var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: 'chart-graph-' + _vm.chartref,
    attrs: {
      "id": 'chart-graph-' + _vm.chartref
    }
  }, [_c('Select', {
    staticClass: "graph-search-bar",
    attrs: {
      "id": "search-vendor-graph-bar",
      "filterable": "",
      "placeholder": _vm.$t('global.graph.find_vendor')
    },
    on: {
      "on-change": _vm.handleGraphFilterChange
    }
  }, _vm._l(_vm.getVendorNodesList, function (item) {
    return _c('Option', {
      key: item.value,
      attrs: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _c('br'), _c('br'), _c('div', {
    staticClass: "overlay"
  }, [_vm.selectedNode ? _c('Card', {
    staticClass: "graph-vendor-card"
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.selectedNode.vendorName || _vm.$t('global.filters.under_qualification')) + " ")]), _c('ul', [_vm.selectedNode.group == 0 ? _c('li', [_c('i', {
    staticClass: "compliance-0"
  }), _vm._v(_vm._s(_vm.$t('global.graph.website')) + " ")]) : _vm._e(), _vm.selectedNode.group == 1 ? _c('li', [_c('i', {
    staticClass: "compliance-A"
  }), _vm._v(_vm._s(_vm.$t('global.graph.direct_call_only')) + " ")]) : _vm._e(), _vm.selectedNode.group == 2 ? _c('li', [_c('i', {
    staticClass: "compliance-B"
  }), _vm._v(_vm._s(_vm.$t('global.graph.direct_n_tms')) + " ")]) : _vm._e(), _vm.selectedNode.group == 3 ? _c('li', [_c('i', {
    staticClass: "compliance-C"
  }), _vm._v(_vm._s(_vm.$t('global.graph.direct_n_indirect')) + " ")]) : _vm._e(), _vm.selectedNode.group == 4 ? _c('li', [_c('i', {
    staticClass: "compliance-D"
  }), _vm._v(_vm._s(_vm.$t('global.graph.indirect_call_only')) + " ")]) : _vm._e(), _vm.selectedNode.group == 5 ? _c('li', [_c('i', {
    staticClass: "compliance-E"
  }), _vm._v(_vm._s(_vm.$t('global.graph.indirect_call_only_to_vendor')) + " ")]) : _vm._e()]), _c('br'), _c('span', {
    staticStyle: {
      "display": "block",
      "cursor": "pointer",
      "color": "#1CA08D",
      "font-weight": "bold"
    },
    on: {
      "click": _vm.showDrawer
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.trackers')) + " : "), _vm.selectedNode.uniqueTrackersCount === 0 ? _c('span', {
    staticClass: "chips chips--green"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.no')) + " ")]) : _c('span', {
    staticClass: "chips chips--orange"
  }, [_vm._v(" " + _vm._s(_vm.selectedNode.uniqueTrackersCount) + " ")])]), _c('ApiWidgetFocus', {
    attrs: {
      "show": _vm.focus,
      "api": {
        cookie: 'getCookies',
        pgtrackers: 'getPgTrackers'
      },
      "namespace": 'cookie',
      "description": {
        title: 'Trackers drop by ' + _vm.selectedNode.vendorName
      },
      "params": Object.assign({}, _vm.trackersParams, {
        properties: [_vm.property.id],
        period: _vm.period
      })
    }
  }), _c('br'), _c('br'), _vm._v(" " + _vm._s(this.$t('global.graph.requests')) + " "), _c('span', {
    staticClass: "chips chips--orange"
  }, [_vm._v(_vm._s(_vm.selectedNode.numRequests || 'N/A'))]), _c('ul', [_c('li', [_vm._v("- " + _vm._s(this.$t('global.graph.from_website')) + " "), _c('span', {
    staticClass: "chips chips--orange"
  }, [_vm._v(_vm._s(Math.round(_vm.selectedNode.ratioDirectStrict * 100, 1) + '%' || 'N/A'))])]), _c('li', [_vm._v("- " + _vm._s(this.$t('global.graph.from_vendors')) + " "), _c('span', {
    staticClass: "chips chips--orange"
  }, [_vm._v(_vm._s(Math.round((1 - _vm.selectedNode.ratioDirectStrict) * 100, 1) + '%' || 'N/A'))])])]), _c('br'), _c('ul', [_c('li', [_vm._v(_vm._s(this.$t('global.domains')) + " - " + _vm._s(Object.values(_vm.selectedNode.slds).length))]), _vm._l(_vm.selectedNode.slds, function (value, name) {
    return _c('li', {
      key: name
    }, [_vm._v("   - " + _vm._s(name) + " : " + _vm._s(value) + " ")]);
  })], 2), _c('br'), _c('ul', [_c('li', [_vm._v(_vm._s(this.$t('Requested by')) + " - " + _vm._s(Object.values(_vm.selectedNode.initiatorSlds).length))]), _vm._l(_vm.selectedNode.initiatorSlds, function (value, name) {
    return _c('li', {
      key: name
    }, [_vm._v("   - " + _vm._s(name) + " : " + _vm._s(value) + " ")]);
  })], 2), _c('br'), _vm.selectedNode.vendorUuid ? _c('router-link', {
    attrs: {
      "tag": "div",
      "to": {
        name: 'Vendors view',
        params: {
          uuid: _vm.selectedNode.vendorUuid
        }
      }
    }
  }, [_c('Button', {
    staticClass: "btn--elevated",
    attrs: {
      "type": "primary",
      "icon": "ios-link",
      "long": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.graph.view_vendor')) + " ")])], 1) : _vm._e()], 2) : _vm._e()], 1), _vm.isLoading ? _c('div', [_c('Progress', {
    attrs: {
      "percent": _vm.progressText,
      "stroke-color": ['#81C327', '#6BAAD8']
    }
  })], 1) : _vm._e(), _vm.showGraph ? _c('div', {
    attrs: {
      "id": _vm.graphId
    }
  }) : _vm._e(), _c('div', {
    attrs: {
      "id": "legend"
    }
  }, [_c('ul', [_c('li', [_c('i', {
    staticClass: "compliance-0"
  }), _vm._v(_vm._s(_vm.$t('global.graph.website')))]), _c('li', [_c('i', {
    staticClass: "compliance-A"
  }), _vm._v(_vm._s(_vm.$t('global.graph.direct_call_only')))]), _c('li', [_c('i', {
    staticClass: "compliance-B"
  }), _vm._v(_vm._s(_vm.$t('global.graph.direct_n_tms')))]), _c('li', [_c('i', {
    staticClass: "compliance-C"
  }), _vm._v(_vm._s(_vm.$t('global.graph.direct_n_indirect')))]), _c('li', [_c('i', {
    staticClass: "compliance-D"
  }), _vm._v(_vm._s(_vm.$t('global.graph.indirect_call_only')))]), _c('li', [_c('i', {
    staticClass: "compliance-E"
  }), _vm._v(_vm._s(_vm.$t('global.graph.indirect_call_only_to_vendor')))])]), _c('ul', [_c('li', {
    staticClass: "shape"
  }, [_c('svg', {
    attrs: {
      "width": "25",
      "height": "21"
    }
  }, [_c('polygon', {
    attrs: {
      "fill": "transparent",
      "stroke-width": "1.5",
      "stroke": "black",
      "points": "2 18, 10 2, 18 18"
    }
  })]), _vm._v(" Trackers dropped ")]), _c('li', {
    staticClass: "shape"
  }, [_c('svg', {
    attrs: {
      "width": "25",
      "height": "21"
    }
  }, [_c('circle', {
    attrs: {
      "fill": "transparent",
      "stroke-width": "1.5",
      "stroke": "black",
      "r": "8",
      "cx": "10",
      "cy": "10"
    }
  })]), _vm._v(" No trackers dropped ")])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }