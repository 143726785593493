var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: _vm.chartref,
    staticClass: "widget-map widget-content"
  }, [_c('canvas', {
    attrs: {
      "id": _vm.chartref
    }
  }), _vm.legend.length ? _c('ul', {
    staticClass: "legend"
  }, _vm._l(_vm.orderLegend, function (i) {
    return _c('li', {
      key: i.color
    }, [_c('i', {
      class: 'compliance-' + i.compliance
    }), _vm._v(_vm._s(i.label) + " ")]);
  }), 0) : _vm._e(), !_vm.values.length ? _c('NoData') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }