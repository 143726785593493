var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.link ? _c('a', {
    staticClass: "two-values-content",
    on: {
      "click": _vm.click
    }
  }, [_vm.value1 ? _c('div', {
    staticClass: "big-number"
  }, [_c('span', [_vm._v(_vm._s(_vm.value1))])]) : _vm._e(), _vm.value2 ? _c('div', {
    staticClass: "alt-number"
  }, [_vm._v(" " + _vm._s(_vm.value2) + " ")]) : _vm._e()]) : _c('div', {
    staticClass: "two-values-content"
  }, [_vm.value1 ? _c('div', {
    staticClass: "big-number"
  }, [_c('span', [_vm._v(_vm._s(_vm.value1))])]) : _vm._e(), _vm.value2 ? _c('div', {
    staticClass: "alt-number"
  }, [_vm._v(" " + _vm._s(_vm.value2) + " ")]) : _vm._e(), !_vm.apiRes.value1 && !_vm.apiRes.value2 ? _c('NoData') : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }