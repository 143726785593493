<template lang="html">
  <div>
    <ul
      v-if="hasValue && risk"
      class="rating"
    >
      <li
        :class="{'active': risk === 'A'}"
        :description="$t('global.index.excellent')"
      >
        A
      </li>
      <li
        :class="{'active': risk === 'B'}"
        :description="$t('global.index.good')"
      >
        B
      </li>
      <li
        :class="{'active': risk === 'C'}"
        :description="$t('global.index.average')"
      >
        C
      </li>
      <li
        :class="{'active': risk === 'D'}"
        :description="$t('global.index.ngood')"
      >
        D
      </li>
      <li
        :class="{'active': risk === 'E'}"
        :description="$t('global.index.absolute')"
      >
        E
      </li>
    </ul>
    <NoData v-else />
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import NoData from '@/components/ui/NoData'

export default {
  name: 'WidgetOneValue',
  components: {
    NoData
  },
  data () {
    return {
      risk: null
    }
  },
  props: {
    apiRes: {
      type: Object,
      required: true
    },
    unit: {
      type: String,
      default: '',
      note: 'unit to use'
    },
    overview: {
      type: Boolean,
      required: false
    }
  },
  watch: {
    apiRes: {
      deep: true,
      immediate: true,
      handler: function (val, old) {
        this.defineRisk()
      }
    }
  },
  computed: {
    hasValue () {
      return this.apiRes.value.length
    },
    link () {
      return this.apiRes.link ? this.apiRes.link : ''
    },
    gaugeNumber () {
      return this.apiRes && Number(this.apiRes.value)
    }
  },
  mounted () {
    this.defineRisk()
  },
  methods: {
    ...mapActions({
      getComplianceIndex: 'cookie/getComplianceIndexDailies',
      setFocus: 'scope/setFocus'
    }),
    click () {
      this.$router.push('/compliance-index')
    },
    defineRisk () {
      const {apiRes} = this
      const val = parseInt(apiRes.value)
      let risk = null
      if (val === 1) risk = 'A'
      if (val === 2) risk = 'B'
      if (val === 3) risk = 'C'
      if (val === 4) risk = 'D'
      if (val === 5) risk = 'E'
      this.risk = risk
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../../assets/styles/variables";

.rating {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;

  > li {
    color: #fff;
    cursor: pointer;
    flex: 1 0 auto;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight:200;
    font-size:14px;
    &:first-child {
      background: $compliance-A;
      border: 8px solid $compliance-A;
      border-radius: 16px 0 0 16px;
    }
    &:nth-child(2) {
      background: $compliance-B;
      border: 8px solid $compliance-B;
    }
    &:nth-child(3) {
      background: $compliance-C;
      border: 8px solid $compliance-C;
    }
    &:nth-child(4) {
      background: $compliance-D;
      border: 8px solid $compliance-D;
    }
    &:last-child {
      background: $compliance-E;
      border: 8px solid $compliance-E;
      border-radius: 0 16px 16px 0;
    }
    &.active {
      flex-grow: 2;
      height:80px;
      border-radius: 20px;
      font-size:40px;
      //&:after {
      //  content: attr(description);
      //  display: block;
      //  color: #707070;
      //  position:absolute;
      //  width:100%;
      //  left:0;
      //  font-size:24px;
      //  font-weight:300;
      //  margin-top: 140px;
      //  text-align: center;
      //}
    }
  }
}

</style>
