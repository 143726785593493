var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-table widget-content"
  }, [!_vm.data.length ? _c('NoData') : _c('Table', {
    staticClass: "table with-header-border",
    attrs: {
      "stripe": "",
      "size": "small",
      "border": false,
      "disabled-hover": !_vm.link,
      "columns": _vm.columns,
      "data": _vm.data,
      "row-class-name": _vm.rowClassName
    },
    on: {
      "on-row-click": _vm.click
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }