var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasValue ? _c('div', {
    staticClass: "inner-container"
  }, [_c('ChartPie', {
    attrs: {
      "chartref": _vm.chartref,
      "doughnut": "",
      "colors": _vm.colors,
      "options": _vm.computeOptions,
      "datasets": _vm.datasets
    },
    on: {
      "clickchart": _vm.clickchart
    }
  })], 1) : _c('NoData');

}
var staticRenderFns = []

export { render, staticRenderFns }