<template lang="html">
  <Card>
    <template #title>
      <!--not loaded-->
      <template v-if="!!apiRes">
        <!--title && tooltip-->
        <template v-if="title">
          <template v-if="help">
            <Poptip
              trigger="hover"
              :title="title"
              word-wrap
              width="400"
              :content="help"
            >
              {{ title }}
            </Poptip>
          </template>
          <template v-else>
            {{ title }}
          </template>
        </template>
        <template v-else-if="!!apiRes.title && !!apiRes.help">
          <Poptip
            trigger="hover"
            style="padding-right:55px"
            :title="apiRes.title"
            word-wrap
            width="400"
            :content="apiRes.help"
          >
            {{ apiRes.title }}
          </Poptip>
        </template>
        <!--title only-->
        <template v-else-if="!!apiRes.title">
          {{ apiRes.title }}
        </template>
        <template v-else>
          ...
        </template>
      </template>
      <template v-else>
        ...
      </template>
    </template>
    <template #extra>
      <div class="header-buttons">
        <WidgetResponseModal
          v-if="userHasRole('super-admin')"
          :urls="[url]"
          :parameters="[parameters]"
          :responses="[apiRes]"
          :labels="[title]"
        />
        <Dropdown
          v-for="l in links"
          :key="l.format"
          @on-click="(name) => download(name, l.link)"
        >
          <Button
            size="small"
            type="text"
            :loading="isExportingFile"
          >
            <i class="fas fa-ellipsis-h" />
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="csv">
              Download CSV
            </DropdownItem>
            <DropdownItem name="jpeg">
              Download Jpeg
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <!-- <Button
        v-for="l in links"
        :key="l.format"
        size="small"
        type="text"
        :loading="isExportingFile"
        @click="exportFile(l.link)"
      >
        <Icon type="md-download" />
      </Button> -->

        <template v-if="isGraph('DONUT') || isGraph('LINECHART') || isGraph('MAP') || isGraph('STACKEDCHART')">
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <Button
            v-for="l in links"
            :key="l.format + 'resize'"
            size="small"
            type="text"
            @click="modal = !modal"
            :loading="isExportingFile"
          >
            <Icon type="md-resize" />
          </Button>
        </template>
      </div>
    </template>
    <!--loader-->
    <!--    <div v-if="!hasRes" class="image image&#45;&#45;widget"></div>-->
    <slot name="before" />
    <Modal
      class="modal-graph"
      width="1200"
      :lock-scroll="true"
      :title="title"
      v-model="modal"
    >
      <template v-if="modal">
        <WidgetDoughnut
          v-if="isType('DONUT')"
          @close-modal="closeModal"
          :route-link="routeLink"
          :unit="unit"
          :api-res="apiRes"
        />

        <WidgetLineChart
          v-if="isType('LINECHART')"
          @close-modal="closeModal"
          :route-link="routeLink"
          :unit="unit"
          :api-res="apiRes"
        />

        <WidgetMap
          v-if="isType('MAP')"
          @close-modal="closeModal"
          :route-link="routeLink"
          :unit="unit"
          :api-res="apiRes"
        />

        <WidgetStackedChart
          v-if="isType('STACKEDCHART')"
          @close-modal="closeModal"
          :route-link="routeLink"
          :unit="unit"
          :api-res="apiRes"
        />
      </template>
    </Modal>
    <template v-if="!hasRes">
      <Spin fix>
        <Icon
          type="ios-loading"
          size="18"
          class="demo-spin-icon-load"
        />
        <div>{{ $t('global.loading') }}</div>
      </Spin>
    </template>
    <template v-else>
      <slot />
      <WidgetBar
        v-if="isType('BAR')"
        :chartref="_uid"
        :route-link="routeLink"
        :unit="unit"
        :overview="overview"
        :api-res="api"
      />

      <WidgetOneValue
        v-if="isType('ONEVALUE')"
        :route-link="routeLink"
        :unit="unit"
        :overview="overview"
        :vendors="vendors"
        :cookies="cookies"
        :api-res="apiRes"
      />

      <WidgetRisk
        v-if="isType('GAUGE')"
        :chartref="_uid"
        :route-link="routeLink"
        :unit="unit"
        :api-res="apiRes"
      />

      <WidgetTwoValues
        v-if="isType('TWOVALUES')"
        :route-link="routeLink"
        :unit="unit"
        :overview="overview"
        :api-res="apiRes"
      />

      <WidgetTable
        v-if="isType('TABLE')"
        :route-link="routeLink"
        :unit="unit"
        :api-res="apiRes"
      />

      <WidgetDoughnut
        v-if="isType('DONUT')"
        :chartref="_uid"
        :route-link="routeLink"
        :unit="unit"
        :api-res="apiRes"
      />

      <WidgetLineChart
        v-if="isType('LINECHART')"
        :chartref="_uid"
        :route-link="routeLink"
        :unit="unit"
        :api-res="apiRes"
      />

      <WidgetMap
        v-if="isType('MAP')"
        :chartref="_uid"
        :route-link="routeLink"
        :unit="unit"
        :api-res="apiRes"
      />

      <!-- GRAPH -->
      <WidgetGraph
        v-if="isType('GRAPH') && resetWidget !== true"
        :chartref="_uid"
        :route-link="routeLink"
        :unit="unit"
        :api-res="apiRes"
      />

      <WidgetStackedChart
        v-if="isType('STACKEDCHART')"
        :chartref="_uid"
        :route-link="routeLink"
        :unit="unit"
        :api-res="apiRes"
      />
    </template>
    <slot name="after" />
  </Card>
</template>

<script>
import FileDownload from 'js-file-download'

import WidgetOneValue from '@/components/widgets/WidgetOneValue'
import WidgetRisk from '@/components/widgets/WidgetRisk'
import WidgetTwoValues from '@/components/widgets/WidgetTwoValues'
import WidgetLineChart from '@/components/widgets/WidgetLineChart'
import WidgetTable from '@/components/widgets/WidgetTable'
import WidgetMap from '@/components/widgets/WidgetMap'
import WidgetGraph from '@/components/widgets/WidgetGraph'
import WidgetDoughnut from '@/components/widgets/WidgetDoughnut'
import WidgetStackedChart from '@/components/widgets/WidgetStackedChart'
import WidgetBar from '@/components/widgets/WidgetBar'
import { eventBus } from '@/main'
import { mapGetters } from 'vuex'
import WidgetResponseModal from '../ui/WidgetResponseModal'

export default {
  name: 'Widget',
  components: {
    WidgetResponseModal,
    WidgetBar,
    WidgetOneValue,
    WidgetTwoValues,
    WidgetTable,
    WidgetMap,
    WidgetLineChart,
    WidgetDoughnut,
    WidgetStackedChart,
    WidgetGraph,
    WidgetRisk
  },
  props: {
    widgetStyle: {
      type: String,
      default: '',
      note: 'force widget type'
    },
    apiRes: {
      type: Object,
      default: () => {},
      required: false
    },
    title: {
      type: String,
      default: ''
    },
    help: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: '',
      note: 'unit to use'
    },
    vendors: {
      type: Boolean,
      default: false
    },
    cookies: {
      type: Boolean,
      default: false
    },
    overview: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    routeLink: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: () => ''
    },
    parameters: {
      type: Object,
      default: () => ''
    }
  },
  data () {
    return {
      isExportingFile: false,
      // uniqueId: Math.random()*1000,
      resetWidget: false,
      api: this.apiRes,
      modal: false
    }
  },
  watch: {
    qs: function (_) {
      this.api = {}
    },
    apiRes: function (val, old) {
      this.resetWidget = true
      this.resetWidget = false
      this.api = val
    }
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',
      hash: 'hash',
      qs: 'scope/qs'
    }),
    hasRes () {
      const keys = this.api
        ? Object.keys(this.api)
        : null
      return keys
        ? keys.length
        : false
    },
    links () {
      if (this.hasRes && this.apiRes.export) {
        const formats = Object.keys(this.apiRes.export)
        return formats.length
          ? formats.map(format => {
            return {
              format,
              link: this.apiRes.export[format]
            }
          })
          : []
      } else {
        return []
      }
    },
    isExportable () {
      return this.hasRes && this.apiRes.isExportableCsv
    }
  },
  methods: {
    getExportFileName (name = 'csv') {
      return name == 'csv'
        ? (this.apiRes && this.apiRes.csvFileName + '.csv') || 'export.csv'
        : (this.apiRes && this.apiRes.csvFileName + '.jpg') || 'chart.jpg'
    },
    isGraph (name) {
      return this.apiRes && this.apiRes.widget === name
    },
    isType (defaultWidgetStyle) {
      if (this.widgetStyle) return defaultWidgetStyle === this.widgetStyle
      return this.apiRes.widget === defaultWidgetStyle
    },
    closeModal () {
      this.modal = false
    },
    download (name, link) {
      if (name === 'csv') return this.exportFile(link)
      if (name === 'jpeg') return this.exportJpeg()
    },
    exportJpeg () {
      const ref = this._uid
      const name = this.getExportFileName('jpeg')
      eventBus.$emit('download-chart-as-jpeg', {id: ref, name: name })
    },
    async exportFile (link) {
      this.isExportingFile = true

      try {
        const response = await this.$axios.get(link, {
          cache: {
            exclude: {query: true}
          },
          responseType: 'blob', // Excepting a buffer stream
          headers: {
            'Accept': 'application/octet-stream'
          }
        })
        FileDownload(response.data, this.getExportFileName())
      } catch (err) {
        this.$Message.error('Server error. Please try in a few.')
        console.log(err)
      } finally {
        this.isExportingFile = false
      }
    }
  }
}
</script>
<style lang="scss">
  .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    .demo-spin-col{
        height: 100px;
        position: relative;
        border: 1px solid #eee;
    }
.modal-graph .ivu-modal-footer {display:none!important}

.header-buttons {
  display: flex;
  flex-direction: row;
}
</style>
