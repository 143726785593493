<template lang="html">
  <div class="inner-container">
    <ChartLine
      :chartref="chartref"
      :height="400"
      :datasets="datasets"
      :labels="labels"
      :unit="unit"
      :options="{
        elements: { point: { radius: 3 } },
      }"
      :colors="colors"
      @click="click"
    />
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import {colors} from '@/config'
import {indexToArr} from '@/helpers/arrays'
import ChartLine from '@/components/chart/ChartLine'

export default {
  name: 'WidgetLineChart',
  components: {
    ChartLine
  },
  props: {
    chartref: {
      type: Number,
      note: 'parent uid for ref',
      default: 0
    },
    bar: {
      type: Object,
      default: () => {}
    },
    unit: {
      type: String,
      default: '',
      note: 'unit to use'
    },
    apiRes: {
      type: Object,
      required: true
    }
  },
  computed: {
    labels () {
      return this.values.map(i => i['0'])
    },
    values () {
      return this.apiRes.value && this.apiRes.value.data ? indexToArr(this.apiRes.value.data) : []
    },
    datasets () {
      if (this.apiRes.value && this.apiRes.value.columns) {
        const sets = Object.keys(this.apiRes.value.columns).map(k => {
          return {k, label: this.apiRes.value.columns[k]}
        }).filter(s => s.label !== 'Day')
        const data = indexToArr(this.apiRes.value.data)
        return sets.map(s => {
          s.data = data.map(i => i[s.k])
          return s
        })
      } else {
        return []
      }
    },
    colors () {
      return this.apiRes.value && this.apiRes.value.colors ? Object.keys(this.apiRes.value.colors).map(k => this.apiRes.value.colors[k]) : colors.map(c => c.hex)
    }
  },
  methods: {
    ...mapActions({
      scopeSetFocus: 'scope/setFocus'
    }),
    click (data) {
      this.$emit('close-modal', true)
      const d = data.label
      const k = data.dataset.k
      let focusEndpoint = this.apiRes.value.dailylinks ? this.apiRes.value.dailylinks[k] : null
      if (focusEndpoint) {
        focusEndpoint = focusEndpoint.replace('%%DAY%%', d)
        this.scopeSetFocus(focusEndpoint)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inner-container {
   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  height: 100%;

 > .chart {
    width: 100%;
  }}
</style>
