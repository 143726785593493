<template lang="html">
  <!--  <div class="tile-wrapper" :class="type">-->
  <!--    <div class="tile-wrapper" :class="type +' image image&#45;&#45;widget'">-->
  <div data-tests="widget-container">
    <Widget
      class="tile tile--scale-height"
      :class="isActionableClass"
      :title="title"
      :help="help"
      :widget-style="widgetStyle"
      :overview="overview"
      :vendors="vendors"
      :cookies="cookies"
      :api-res="apiResData"
      :route-link="routeLink"
      :unit="unit"
      :type="type"
      :url="endpoint"
      :parameters="parameters"
    >
      <template slot="before">
        <slot name="before" />
      </template>
      <slot />
      <template slot="after">
        <slot name="after" />
        <Alert
          v-if="error"
          type="error"
          show-icon
        >
          {{ error.title }}<template slot="desc">
            {{ error.desc }}
          </template>
        </Alert>
      </template>
    </Widget>
  </div>
  <!--    </div>-->
  <!--  <div class="tile-wrapper" :class="type">-->
  <!--  <Widget class="tile tile&#45;&#45;scale-height" :class="isActionableClass"-->
  <!--          v-if="!isLoading && hasRes"-->
  <!--          :widgetStyle="widgetStyle"-->
  <!--          :overview="overview"-->
  <!--          :vendors="vendors"-->
  <!--          :cookies="cookies"-->
  <!--          :apiRes="apiResData"-->
  <!--          :type="type">-->
  <!--    <div slot="widget-is-empty">-->
  <!--      <slot></slot>-->
  <!--    </div>-->
  <!--  </Widget>-->
  <!--  <Card v-else class="placeholder placeholder&#45;&#45;widget">-->
  <!--    <template slot="title">-->
  <!--      Loading...-->
  <!--    </template>-->
  <!--    <div class="image image&#45;&#45;widget"></div>-->
  <!--  </Card>-->
  <!--      <Widget-->
  <!--        v-if="!isLoading && !hasRes"-->
  <!--        :class="type"-->
  <!--        :apiRes="apiResData">-->
  <!--        <div slot="widget-is-empty">-->
  <!--          No Data-->
  <!--        </div>-->
  <!--      </Widget>-->
  <!--  </div>-->
</template>

<script>
import Widget from '../widgets/index'
import {mapGetters} from 'vuex'
import query from 'qs'

export default {
  name: 'WidgetTile',
  components: {
    Widget
  },
  props: {
    widgetStyle: {
      type: String,
      default: '',
      note: 'force widget type'
    },
    title: {
      type: String,
      default: ''
      // required: true
    },
    help: {
      type: String,
      default: ''
      // required: true
    },
    api: {
      type: String,
      default: '',
      // required: true
    },
    vendors: {
      type: Boolean,
      default: false
    },
    cookies: {
      type: Boolean,
      default: false
    },
    overview: {
      type: Boolean,
      default: false
    },
    apiRes: {
      type: Object,
      default: () => {},
    },
    isActionable: {
      type: Boolean
    },
    type: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: '',
      // required: true
    },
    routeLink: {
      type: String,
      default: ''
    },
    useCache: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      scopeQs: 'scope/qs'
    }),
    hasRes () {
      const keys = this.apiResData
        ? Object.keys(this.apiResData)
        : null
      return keys
        ? keys.length
        : false
    },
    isActionableClass () {
      return this.isActionable
        ? 'tile--action'
        : ''
    },
    detailsProps () {
      return this.details
    }
  },
  data () {
    return {
      isLoading: true,
      apiResData: this.apiRes, // apiRes: {}
      error: false,
      endpoint: '',
      parameters: {}
    }
  },
  watch: {
    scopeQs (val, old) {
      if (val && val !== old) this.getData()
      if (!val) this.resetData()
    },
    api (val, old) {
      if (val && val !== old) {
        this.resetData()
        this.getData(true)
      }
      if (!val) this.resetData()
    }
  },
  methods: {
    async getData (loading = true) {
      if (this.scopeQs) {
        if (loading) this.isLoading = true

        try {
          // quickfix : handle qs given in apiUrl
          this.parameters = query.parse(this.scopeQs)
          this.endpoint = this.api + '?' + this.scopeQs
          if (this.api.indexOf('?') !== -1) {
            this.endpoint = this.api + '&' + this.scopeQs
          }
          console.log('ENDPOINT:', this.endpoint)
          const {data: responseData} = await this.$axios.get(this.endpoint, {
            cache: {
              exclude: {query: !this.useCache}
            }
          })

          if (responseData.success) {
            this.isLoading = false
            this.apiResData = responseData.widget
            this.error = false
            if (this.overview) {
              this.$emit(this.apiResData.csvFileName, this.apiResData)
            }
          } else {
            console.error('Response was not a success when fetching WIDGET endpoint.', responseData)
            this.apiResData = {
              title: 'Error'
            }
            this.error = {
              title: 'Could not fetch data (no success).',
              desc: '' + this.api + '?' + this.scopeQs
            }
          }
        } catch (e) {

          console.log(e)
          console.error('Could not fetch WIDGET endpoint. Server error')
          this.apiResData = {
            title: 'Error'
          }
          this.error = {
            title: 'Could not fetch data (exception).',
            desc: '' + this.api + '?' + this.scopeQs
          }
        } finally {
          // this.isLoading = false
        }
      }
    },
    resetData () {
      this.apiResData = {}
      this.error = false
    }
  },
  mounted () {
    if (this.apiRes) {
      this.isLoading = false
    } else {
      this.getData()
    }
  }
}
</script>

<!--<style lang="scss">-->
<!--  .tile-wrapper{-->
<!--    &.small{-->
<!--      .tile{-->
<!--        box-shadow: none;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--</style>-->
