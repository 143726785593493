var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [!!_vm.apiRes ? [_vm.title ? [_vm.help ? [_c('Poptip', {
          attrs: {
            "trigger": "hover",
            "title": _vm.title,
            "word-wrap": "",
            "width": "400",
            "content": _vm.help
          }
        }, [_vm._v(" " + _vm._s(_vm.title) + " ")])] : [_vm._v(" " + _vm._s(_vm.title) + " ")]] : !!_vm.apiRes.title && !!_vm.apiRes.help ? [_c('Poptip', {
          staticStyle: {
            "padding-right": "55px"
          },
          attrs: {
            "trigger": "hover",
            "title": _vm.apiRes.title,
            "word-wrap": "",
            "width": "400",
            "content": _vm.apiRes.help
          }
        }, [_vm._v(" " + _vm._s(_vm.apiRes.title) + " ")])] : !!_vm.apiRes.title ? [_vm._v(" " + _vm._s(_vm.apiRes.title) + " ")] : [_vm._v(" ... ")]] : [_vm._v(" ... ")]];
      },
      proxy: true
    }, {
      key: "extra",
      fn: function fn() {
        return [_c('div', {
          staticClass: "header-buttons"
        }, [_vm.userHasRole('super-admin') ? _c('WidgetResponseModal', {
          attrs: {
            "urls": [_vm.url],
            "parameters": [_vm.parameters],
            "responses": [_vm.apiRes],
            "labels": [_vm.title]
          }
        }) : _vm._e(), _vm._l(_vm.links, function (l) {
          return _c('Dropdown', {
            key: l.format,
            on: {
              "on-click": function onClick(name) {
                return _vm.download(name, l.link);
              }
            }
          }, [_c('Button', {
            attrs: {
              "size": "small",
              "type": "text",
              "loading": _vm.isExportingFile
            }
          }, [_c('i', {
            staticClass: "fas fa-ellipsis-h"
          })]), _c('DropdownMenu', {
            attrs: {
              "slot": "list"
            },
            slot: "list"
          }, [_c('DropdownItem', {
            attrs: {
              "name": "csv"
            }
          }, [_vm._v(" Download CSV ")]), _c('DropdownItem', {
            attrs: {
              "name": "jpeg"
            }
          }, [_vm._v(" Download Jpeg ")])], 1)], 1);
        }), _vm.isGraph('DONUT') || _vm.isGraph('LINECHART') || _vm.isGraph('MAP') || _vm.isGraph('STACKEDCHART') ? _vm._l(_vm.links, function (l) {
          return _c('Button', {
            key: l.format + 'resize',
            attrs: {
              "size": "small",
              "type": "text",
              "loading": _vm.isExportingFile
            },
            on: {
              "click": function click($event) {
                _vm.modal = !_vm.modal;
              }
            }
          }, [_c('Icon', {
            attrs: {
              "type": "md-resize"
            }
          })], 1);
        }) : _vm._e()], 2)];
      },
      proxy: true
    }])
  }, [_vm._t("before"), _c('Modal', {
    staticClass: "modal-graph",
    attrs: {
      "width": "1200",
      "lock-scroll": true,
      "title": _vm.title
    },
    model: {
      value: _vm.modal,
      callback: function callback($$v) {
        _vm.modal = $$v;
      },
      expression: "modal"
    }
  }, [_vm.modal ? [_vm.isType('DONUT') ? _c('WidgetDoughnut', {
    attrs: {
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "api-res": _vm.apiRes
    },
    on: {
      "close-modal": _vm.closeModal
    }
  }) : _vm._e(), _vm.isType('LINECHART') ? _c('WidgetLineChart', {
    attrs: {
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "api-res": _vm.apiRes
    },
    on: {
      "close-modal": _vm.closeModal
    }
  }) : _vm._e(), _vm.isType('MAP') ? _c('WidgetMap', {
    attrs: {
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "api-res": _vm.apiRes
    },
    on: {
      "close-modal": _vm.closeModal
    }
  }) : _vm._e(), _vm.isType('STACKEDCHART') ? _c('WidgetStackedChart', {
    attrs: {
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "api-res": _vm.apiRes
    },
    on: {
      "close-modal": _vm.closeModal
    }
  }) : _vm._e()] : _vm._e()], 2), !_vm.hasRes ? [_c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t('global.loading')))])], 1)] : [_vm._t("default"), _vm.isType('BAR') ? _c('WidgetBar', {
    attrs: {
      "chartref": _vm._uid,
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "overview": _vm.overview,
      "api-res": _vm.api
    }
  }) : _vm._e(), _vm.isType('ONEVALUE') ? _c('WidgetOneValue', {
    attrs: {
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "overview": _vm.overview,
      "vendors": _vm.vendors,
      "cookies": _vm.cookies,
      "api-res": _vm.apiRes
    }
  }) : _vm._e(), _vm.isType('GAUGE') ? _c('WidgetRisk', {
    attrs: {
      "chartref": _vm._uid,
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "api-res": _vm.apiRes
    }
  }) : _vm._e(), _vm.isType('TWOVALUES') ? _c('WidgetTwoValues', {
    attrs: {
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "overview": _vm.overview,
      "api-res": _vm.apiRes
    }
  }) : _vm._e(), _vm.isType('TABLE') ? _c('WidgetTable', {
    attrs: {
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "api-res": _vm.apiRes
    }
  }) : _vm._e(), _vm.isType('DONUT') ? _c('WidgetDoughnut', {
    attrs: {
      "chartref": _vm._uid,
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "api-res": _vm.apiRes
    }
  }) : _vm._e(), _vm.isType('LINECHART') ? _c('WidgetLineChart', {
    attrs: {
      "chartref": _vm._uid,
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "api-res": _vm.apiRes
    }
  }) : _vm._e(), _vm.isType('MAP') ? _c('WidgetMap', {
    attrs: {
      "chartref": _vm._uid,
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "api-res": _vm.apiRes
    }
  }) : _vm._e(), _vm.isType('GRAPH') && _vm.resetWidget !== true ? _c('WidgetGraph', {
    attrs: {
      "chartref": _vm._uid,
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "api-res": _vm.apiRes
    }
  }) : _vm._e(), _vm.isType('STACKEDCHART') ? _c('WidgetStackedChart', {
    attrs: {
      "chartref": _vm._uid,
      "route-link": _vm.routeLink,
      "unit": _vm.unit,
      "api-res": _vm.apiRes
    }
  }) : _vm._e()], _vm._t("after")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }