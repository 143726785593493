<template lang="html">
  <div>
    <a
      v-if="link"
      class="two-values-content"
      @click="click"
    >
      <div
        class="big-number"
        v-if="value1"
      >
        <span>{{ value1 }}</span>
      </div>
      <div
        class="alt-number"
        v-if="value2"
      >
        {{ value2 }}
      </div>
    </a>
    <div
      v-else
      class="two-values-content"
    >
      <div
        class="big-number"
        v-if="value1"
      >
        <span>{{ value1 }}</span>
      </div>
      <div
        class="alt-number"
        v-if="value2"
      >
        {{ value2 }}
      </div>
      <NoData v-if="!apiRes.value1 && !apiRes.value2" />
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import NoData from '@/components/ui/NoData'

export default {
  name: 'WidgetTwoValues',
  components: {
    NoData
  },
  props: {
    apiRes: {
      type: Object,
      required: true
    },
    overview: {
      type: Boolean,
      note: 'if called from overview'
    },
    unit: {
      type: String,
      default: '',
      note: 'unit to use'
    },
    routeLink: {
      type: String,
      default: ''
    }
  },
  computed: {
    link () {
      return this.apiRes.link ? this.apiRes.link : ''
    },
    value1 () {
      if (this.apiRes.value) {
        return (this.apiRes.value) ? this.prettyDisplay(this.apiRes.value) : null
      }
      return (this.apiRes.value1) ? this.prettyDisplay(this.apiRes.value1) : null
    },
    value2 () {
      return this.apiRes.value2 ? this.prettyDisplay(this.apiRes.value2) : null
    }
  },
  methods: {
    ...mapActions({
      setScopeFocus: 'scope/setFocus'
    }),
    prettyDisplay (value) {
      return !isNaN(Number(value)) ? Number(value).toLocaleString() : value
    },
    click () {
      if (this.link.length && !this.overview) this.setScopeFocus(this.link)
      if (this.overview) return this.redirect()
    },
    redirect () {
      this.$router.push('/trackers')
    }
  }
}
</script>

<style lang="scss" scoped>

    .big-number {
      font-size: 60px;
      font-weight: 100;
      word-break: break-word;
      text-align: center;
      line-height:1;
      //color: #8A9190;
    }

    .alt-number {
      font-size: 14px;
      word-break: break-word;
      text-align: center;
      color: #707070;
    }

</style>
